<div class="container" id="site-footer">
  <footer class="pt-5">
    <div class="row">
      <div class="col-6 col-md-3 mb-3" [ngClass]="{ 'col-md-4': !showSiteColumn }">
        <h5>Features</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/features/results'" class="nav-link p-0 text-body-secondary"
              >Professional Results</a
            >
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/features/scoreboard'" class="nav-link p-0 text-body-secondary"
              >Online Scoreboards</a
            >
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/features/search'" class="nav-link p-0 text-body-secondary">Search</a>
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/features/video'" class="nav-link p-0 text-body-secondary">Video</a>
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/features/notifications'" class="nav-link p-0 text-body-secondary"
              >Notifications</a
            >
          </li>
        </ul>
      </div>

      <div class="col-6 col-md-3 mb-3" [ngClass]="{ 'col-md-4': !showSiteColumn }">
        <h5>AthleticLIVE is for</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/for/timers'" class="nav-link p-0 text-body-secondary">Timers</a>
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/for/meet-directors'" class="nav-link p-0 text-body-secondary"
              >Meet Directors</a
            >
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/for/athletes'" class="nav-link p-0 text-body-secondary">Athletes</a>
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/for/coaches'" class="nav-link p-0 text-body-secondary">Coaches</a>
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/for/parents'" class="nav-link p-0 text-body-secondary">Parents</a>
          </li>
        </ul>
      </div>

      <div class="col-6 col-md-3 mb-3" [ngClass]="{ 'col-md-4': !showSiteColumn }">
        <h5>Links</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/about'" class="nav-link p-0 text-body-secondary">About</a>
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/pricing'" class="nav-link p-0 text-body-secondary"
              >Features & Pricing</a
            >
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/get'" class="nav-link p-0 text-body-secondary">Get AthleticLIVE</a>
          </li>
          <li class="nav-item mb-2">
            <a [href]="'https://live.athletic.net/contact'" class="nav-link p-0 text-body-secondary">Contact Us</a>
          </li>
        </ul>
      </div>

      @if (customFooterColumn) {
        <div class="col-12 col-md-3 mb-3">
          <span [innerHTML]="customFooterColumn"></span>
        </div>
      } @else if (showSiteColumn && siteConfig) {
        <div class="col-12 col-md-3 mb-3">
          <h5>Results By</h5>
          <p>
            <a [href]="siteConfig.ngConfig.siteUrl" [title]="siteConfig.ngConfig.siteName">
              @if ({ value: isDarkMode$ | async }; as isDarkMode) {
                @if (isDarkMode.value) {
                  <img
                    [src]="siteConfig.ngConfig.footerImageDarkModeUrl"
                    [alt]="siteConfig.ngConfig.siteName"
                    width="150"
                  />
                } @else {
                  <img [src]="siteConfig.ngConfig.footerImageUrl" [alt]="siteConfig.ngConfig.siteName" width="150" />
                }
              }
            </a>
          </p>
          <h5>Email</h5>
          <p>
            <a style="overflow-wrap: break-word" href="mailto:{{ siteConfig.ngConfig.contactEmail }}">{{
              siteConfig.ngConfig.contactEmail
            }}</a>
          </p>
        </div>
      }

      @if (siteConfig && siteConfig.ngConfig.customFooterImage) {
        <div class="col-12">
          <img [src]="siteConfig.ngConfig.customFooterImage" [alt]="siteConfig.ngConfig.siteName" width="100%" />
        </div>
      }
    </div>

    <div class="d-flex flex-column flex-sm-row justify-content-between pt-4 mt-4 border-top">
      <div>
        <div>
          © {{ year }} <a href="http://www.runnerspace.com/" target="_blank">RunnerSpace.com</a> -
          <a href="http://about.runnerspace.com/" target="_blank">About</a> -
          <a href="http://about.runnerspace.com/" target="_blank">Advertise</a>
        </div>
        <div>
          © {{ year }} <a href="https://www.athletic.net" target="_blank">Athletic</a> - All rights reserved -
          <a href="https://www.athletic.net/Privacy.aspx" target="_blank">Privacy Policy</a>
        </div>
        <div>
          <small
            ><a target="_blank" href="https://support.athletic.net/article/y47h76y547-athletic-live-changelog"
              >Version {{ version }}</a
            ></small
          >
        </div>
      </div>
      @if (siteConfig) {
        <ul class="list-unstyled d-flex">
          <li class="ms-3">
            <a class="link-body-emphasis" [href]="siteConfig.ngConfig.twitterLink"
              ><fa-icon [icon]="['fab', 'square-x-twitter']"></fa-icon
            ></a>
          </li>
          <li class="ms-3">
            <a class="link-body-emphasis" [href]="siteConfig.ngConfig.facebookLink"
              ><fa-icon [icon]="['fab', 'facebook']"></fa-icon
            ></a>
          </li>
        </ul>
      }
    </div>
  </footer>
</div>
