{
  "name": "alive-angular",
  "version": "3.2.6",
  "scripts": {
    "cy": "cypress open",
    "ng": "ng",
    "start": "npm run deployFixes && ng serve --port=4567 --host=0.0.0.0 --configuration=fr",
    "build": "npm run deployFixes && ng build",
    "build:ssr": "npm run deployFixes && ng build -c=ssr-production",
    "buildprod": "npm run deployFixes && npm run generate:sitemaps && npm run generate:robots && npx createAngularStaticHashes --staticAssetsPath=src/assets --globPattern=/**/config.json && ng build -c=production --deploy-url https://livestatic.athletic.net/ && node_modules/.bin/gulp b",
    "deployprod": "npm run sentry:sourcemaps && npm run deployAzureNonIndexFiles && npm run deployDigitalOceanNonIndexFiles",
    "deployDigitalOceanNonIndexFiles": "rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@web21:/srv/www/trackmeet.io/htdocs/dist_new && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_new && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_new && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@107.170.23.19:/srv/www/trackmeet.io/htdocs/dist_new",
    "deployDigitalOceanIndexFiles": "rsync dist/alive-angular/browser/index.html admin@web21:/srv/www/trackmeet.io/htdocs/dist_new/index.html && rsync dist/alive-angular/browser/index.html admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_new/index.html && rsync dist/alive-angular/browser/index.html admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_new/index.html && rsync dist/alive-angular/browser/index.html admin@107.170.23.19:/srv/www/trackmeet.io/htdocs/dist_new/index.html",
    "deployprodindex": "npm run deployAzureIndexFiles && npm run deployDigitalOceanIndexFiles",
    "deployAzureNonIndexFiles": "rsync -e \"ssh -i /home/spikefalcontwo/.ssh/azure_key.pem\" --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ azureuser@20.94.48.21:/srv/www/trackmeet.io/htdocs/dist_new",
    "deployAzureIndexFiles": "rsync -e \"ssh -i /home/spikefalcontwo/.ssh/azure_key.pem\" dist/alive-angular/browser/index.html azureuser@20.94.48.21:/srv/www/trackmeet.io/htdocs/dist_new/index.html",
    "buildtest": "npm run deployFixes && npx createAngularStaticHashes --staticAssetsPath=src/assets --globPattern=/**/config.json && ng build -c=production && node_modules/.bin/gulp b",
    "deployTest": "rsync --exclude=\"*.map\" -a dist/alive-angular/browser/ admin@web21:/srv/www/trackmeet.io/htdocs/dist_test_new && rsync --exclude=\"*.map\" -a dist/alive-angular/browser/ admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_test_new && rsync --exclude=\"*.map\" -a dist/alive-angular/browser/ admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_test_new && rsync --exclude=\"*.map\" -a dist/alive-angular/browser/ admin@107.170.23.19:/srv/www/trackmeet.io/htdocs/dist_test_new",
    "deployTestAzure": "rsync -e \"ssh -i /home/spikefalcontwo/.ssh/azure_key.pem\" --exclude=\"*.map\" -a dist/alive-angular/browser/ azureuser@20.94.48.21:/srv/www/trackmeet.io/htdocs/dist_test_new",
    "buildProdSingleSite": "npm run deployFixes && npx createAngularStaticHashes --staticAssetsPath=src/assets --globPattern=/**/config.json && ng build -c=production && node_modules/.bin/gulp b",
    "deployProdSingleSite": "npm run sentry:sourcemaps && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@web21:/srv/www/trackmeet.io/htdocs/dist_new_qld && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_new_qld && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_new_qld && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@107.170.23.19:/srv/www/trackmeet.io/htdocs/dist_new_qld",
    "deployProdSingleSiteIndex": "rsync dist/alive-angular/browser/index.html admin@web21:/srv/www/trackmeet.io/htdocs/dist_new_qld/index.html && rsync dist/alive-angular/browser/index.html admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_new_qld/index.html && rsync dist/alive-angular/browser/index.html admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_new_qld/index.html && rsync dist/alive-angular/browser/index.html admin@107.170.23.19:/srv/www/trackmeet.io/htdocs/dist_new_qld/index.html",
    "generate:sitemaps": "node generateSitemaps.js",
    "generate:robots": "node generateRobotsTxt.js",
    "watch": "npm run deployFixes && ng build --watch --configuration development",
    "test": "npm run deployFixes && ng test",
    "deployFixes": "node deployFixes.js",
    "b": "npm run buildprod",
    "ba": "npm run buildprod && ./azcopy copy 'dist/alive-angular/browser/' 'https://athleticliveapp.blob.core.windows.net/$web' --recursive",
    "bd": "npm run buildprod && npm run deployprod",
    "bdtest": "npm run buildtest && npm run deployAzureNonIndexFiles && npm run deployTestAzure && npm run deployTest",
    "dtest": "npm run deployAzureNonIndexFiles && npm run deployTestAzure && npm run deployTest",
    "dev:ssr": "npm run deployFixes && ng serve -c=ssr-development --port=4567 --host=0.0.0.0",
    "serve:ssr": "npm run deployFixes && ng serve -c=ssr-production --port=4567 --host=0.0.0.0",
    "format": "prettier --write '**/?(.)*.{css,scss,js,ts,tsx,json,yaml,yml,html}'",
    "lint:format": "prettier --check '**/?(.)*.{css,scss,js,ts,tsx,json,yaml,yml,html}'",
    "prepare": "husky",
    "serve:ssr:alive-angular": "node dist/alive-angular/server/server.mjs",
    "extracti18n": "ng extract-i18n --out-file src/locale/messages.xlf && npx prettier src/locale/messages.xlf --write --plugin=@prettier/plugin-xml",
    "sentry:sourcemaps": "sentry-cli releases new --org spikefalcon --project athletic-live-angular $npm_package_version && sentry-cli sourcemaps inject --release=$npm_package_version --org spikefalcon --project athletic-live-angular ./dist/alive-angular/browser && sentry-cli sourcemaps upload --release=$npm_package_version --org spikefalcon --project athletic-live-angular ./dist/alive-angular/browser",
    "buildprod_en": "npm run deployFixes && npx createAngularStaticHashes --staticAssetsPath=src/assets --globPattern=/**/config.json && ng build -c=production && node_modules/.bin/gulp b --locale en",
    "buildprod_fr": "npm run deployFixes && npx createAngularStaticHashes --staticAssetsPath=src/assets --globPattern=/**/config.json && ng build -c=production,fr && node_modules/.bin/gulp b --locale fr",
    "buildprod_es": "npm run deployFixes && npx createAngularStaticHashes --staticAssetsPath=src/assets --globPattern=/**/config.json && ng build -c=production,es && node_modules/.bin/gulp b --locale es",
    "deployDigitalOceanNonIndexFiles_en": "rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@web21:/srv/www/trackmeet.io/htdocs/dist_alive && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_alive && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_alive && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser/ admin@162.243.63.17:/srv/www/trackmeet.io/htdocs/dist_alive",
    "deployDigitalOceanIndexFiles_en": "rsync dist/alive-angular/browser/index.html admin@web21:/srv/www/trackmeet.io/htdocs/dist_alive/index.html && rsync dist/alive-angular/browser/index.html admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_alive/index.html && rsync dist/alive-angular/browser/index.html admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_alive/index.html && rsync dist/alive-angular/browser/index.html admin@162.243.63.17:/srv/www/trackmeet.io/htdocs/dist_alive/index.html",
    "deployDigitalOceanNonIndexFiles_fr": "rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser-fr/browser/fr/ admin@web21:/srv/www/trackmeet.io/htdocs/dist_alive/fr && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser-fr/browser/fr/ admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_alive/fr && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser-fr/browser/fr/ admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_alive/fr && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser-fr/browser/fr/ admin@162.243.63.17:/srv/www/trackmeet.io/htdocs/dist_alive/fr",
    "deployDigitalOceanIndexFiles_fr": "rsync dist/alive-angular/browser-fr/browser/fr/index.html admin@web21:/srv/www/trackmeet.io/htdocs/dist_alive/fr/index.html && rsync dist/alive-angular/browser-fr/browser/fr/index.html admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_alive/fr/index.html && rsync dist/alive-angular/browser-fr/browser/fr/index.html admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_alive/fr/index.html && rsync dist/alive-angular/browser-fr/browser/fr/index.html admin@162.243.63.17:/srv/www/trackmeet.io/htdocs/dist_alive/fr/index.html",
    "deployDigitalOceanNonIndexFiles_es": "rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser-es/browser/es/ admin@web21:/srv/www/trackmeet.io/htdocs/dist_alive/es && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser-es/browser/es/ admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_alive/es && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser-es/browser/es/ admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_alive/es && rsync --exclude=\"{'index.html','*.map'}\" -a dist/alive-angular/browser-es/browser/es/ admin@162.243.63.17:/srv/www/trackmeet.io/htdocs/dist_alive/es",
    "deployDigitalOceanIndexFiles_es": "rsync dist/alive-angular/browser-es/browser/es/index.html admin@web21:/srv/www/trackmeet.io/htdocs/dist_alive/es/index.html && rsync dist/alive-angular/browser-es/browser/es/index.html admin@107.170.36.19:/srv/www/trackmeet.io/htdocs/dist_alive/es/index.html && rsync dist/alive-angular/browser-es/browser/es/index.html admin@192.241.173.6:/srv/www/trackmeet.io/htdocs/dist_alive/es/index.html && rsync dist/alive-angular/browser-es/browser/es/index.html admin@162.243.63.17:/srv/www/trackmeet.io/htdocs/dist_alive/es/index.html"
  },
  "engines": {
    "node": "18.x"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.0",
    "@angular/cdk": "^18.1.0",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.1.0",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/platform-server": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@angular/ssr": "^18.1.0",
    "@athleticnet/athletic-live-api": "1.2.119",
    "@athleticnet/athletic-live-api-models": "1.0.212",
    "@brightcove/player-loader": "^1.8.0",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "^6.5.1",
    "@fortawesome/free-brands-svg-icons": "^6.5.1",
    "@fortawesome/pro-duotone-svg-icons": "^6.5.1",
    "@fortawesome/pro-light-svg-icons": "^6.5.1",
    "@fortawesome/pro-regular-svg-icons": "^6.5.1",
    "@fortawesome/pro-solid-svg-icons": "^6.5.1",
    "@fortawesome/sharp-regular-svg-icons": "^6.5.1",
    "@fortawesome/sharp-solid-svg-icons": "^6.5.1",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@popperjs/core": "^2.11.8",
    "@sentry/angular": "^8.13.0",
    "@sitelintcode/angular-static-assets-hash": "^0.0.21",
    "@types/video.js": "^7.3.46",
    "bootstrap": "^5.3.2",
    "express": "^4.18.2",
    "luxon": "^3.4.3",
    "minimist": "^1.2.8",
    "ngx-cookie-service": "^18.0.0",
    "ngx-countdown": "^16.0.0",
    "rxjs": "~7.8.0",
    "swiper": "^10.3.1",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.0",
    "@angular/cli": "~18.1.0",
    "@angular/compiler-cli": "^18.1.0",
    "@angular/localize": "^18.1.0",
    "@sentry/cli": "^2.31.0",
    "@prettier/plugin-xml": "^3.4.1",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~4.3.0",
    "@types/luxon": "^3.3.2",
    "@types/node": "^18.18.0",
    "browser-sync": "^3.0.0",
    "cypress": "^12.16.0",
    "gulp": "^4.0.2",
    "gulp-inject": "^5.0.5",
    "gulp-inject-string": "^1.1.2",
    "gulp-replace": "^1.1.4",
    "husky": "^9.0.11",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.2",
    "prettier": "^3.2.5",
    "replace-in-file": "^7.1.0",
    "source-map-explorer": "^2.5.3",
    "typescript": "~5.4.5"
  },
  "overrides": {
    "@ng-bootstrap/ng-bootstrap": {
      "@angular/core": "^18.0.0",
      "@angular/common": "^18.0.0",
      "@angular/forms": "^18.0.0",
      "@angular/localize": "^18.0.0"
    }
  },
  "lint-staged": {
    "*.{css,scss,js,ts,tsx,json,yaml,yml,html}": "prettier --write"
  }
}
